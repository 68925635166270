import { Dispatch, SetStateAction, useState } from "react";
import { useSelector } from "react-redux";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import addApi from "src/api/add";
import {
  TAdminSite,
  TAdminSiteCreatePayload,
  TAdminSiteUpdatePayload,
} from "src/store/commonReducer";
import {
  selectAllCities,
  selectAllCurrencies,
  selectAllDirections,
  TGlobalDirection,
  TSelect,
} from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { Field } from "src/components/FormElements/Field";
import { Tumbler } from "src/components/FormElements/Tumbler";
import { DBForm } from "./DBForm";
import { Exception } from "./Exception";
import { PEForm } from "./PEForm";

type TProps = {
  data: TAdminSite;
  setMessage: Dispatch<SetStateAction<string>>;
  setStatus: Dispatch<SetStateAction<boolean | null>>;
  fetchAdminSites: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  access: boolean;
};

export const SiteForm = ({
  data,
  setMessage,
  setStatus,
  fetchAdminSites,
  setIsLoading,
  access,
}: TProps) => {
  const methods = useForm();
  const globalCities = useSelector(selectAllCities);
  const globalCurrencies = useSelector(selectAllCurrencies);
  const globalDirections = useSelector(selectAllDirections);
  const citiesOptions = globalCities?.map((el) => ({ label: el.city_name, value: el.city_id }));
  const currenciesOptions = globalCurrencies?.map((el) => ({
    label: el.currency_name,
    value: el.currency_id,
  }));
  const directionsOptions = globalDirections?.map((el) => ({
    label: `${el.currency_from}-to-${el.currency_to}`,
    value: el,
  }));
  const [isAddExc, setAddExc] = useState(false);
  const [currentCity, setCurrentCity] = useState<TSelect>();
  const [isEditCoreToken, setEditCoreToken] = useState(false);
  const [currentVectors, setCurrentVectors] = useState<
    Array<{ label: string; value: TGlobalDirection }>
  >([]);
  const isPE = methods.watch("is_pe");
  const disabled = !currentCity;

  const updateSite = async (e: FieldValues) => {
    setIsLoading(true);
    const payloadDB = data?.db_data?.map((el) => ({
      driver: el?.driver,
      port: +e[`port-${el?.driver}`],
      host: e[`host-${el?.driver}`],
      username: e[`username-${el?.driver}`],
      database: e[`database-${el?.driver}`],
      prefix: e[`prefix-${el?.driver}`],
      table_name: e[`table_name-${el?.driver}`],
    }));
    const preparedPayload = {
      id: data?.id,
      site_id: data?.site_id,
      site_name: e.site_name,
      tumbler_site: e.tumbler_site,
      tumbler_scam_checker: e.tumbler_scam_checker,
      update_site: e.update_site,
      has_procedure: e.has_procedure,
      site_url_xml: e.site_url_xml,
      site_uuid: data.site_uuid,
      update_turn_status: e.update_turn_status,
      db_access: e.db_access,
      is_on_best: e.is_on_best,
      update_new: e.update_new,
      is_pe: e.is_pe,
      site_url: e.site_url,
      real_xml: e.real_xml,
      xml_json: e.xml_json,
      core_api_url: e.core_api_url,
      db_data: payloadDB,
    };
    const payload = isPE
      ? {
          ...preparedPayload,
          pe_login: e.pe_login,
          pe_password: e.pe_password,
          pe_link: e.pe_link,
          pe_version: e.pe_version,
        }
      : preparedPayload;

    const { response, error } = await fetchRequest(
      addApi.updateSite(!!e.exnode_id ? { ...payload, exnode_id: +e.exnode_id } : payload),
      { request: "Обновление кредо" }
    );
    if (response) {
      setMessage(`Данные успешно обновлены`);
      setStatus(true);
      await fetchAdminSites();
    }
    if (error) {
      setMessage("Не удалось обновить данные");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
    }, 4600);
  };

  const baseExcRequest = async (
    request: "add" | "update" | "delete",
    title: "Создание исключения" | "Удаление исключения",
    data: TAdminSiteUpdatePayload | TAdminSiteCreatePayload
  ) => {
    const { response } = await fetchRequest(addApi[`${request}Exception`](data), {
      request: title,
    });
    if (response) {
      await fetchAdminSites();
    }
  };

  const updateCoreToken = async () => {
    setIsLoading(true);
    const payload = {
      site_id: data?.site_id,
      core_api_token: methods.watch(`core_api_token`),
    };
    const { response, error } = await fetchRequest(addApi.updateSiteCoreToken(payload), {
      request: "Обновление токена",
    });
    if (response) {
      setMessage(`Токен успешно обновлен`);
      setStatus(true);
      await fetchAdminSites();
    }
    if (error) {
      setMessage("Не удалось обновить токен");
      setStatus(false);
    }
    setTimeout(() => {
      setStatus(null);
      setMessage("");
      setIsLoading(false);
      setEditCoreToken(false);
    }, 4600);
  };

  return (
    <FormProvider {...methods}>
      <form
        className="pt-[10px] pr-[6px] flex flex-col gap-[20px]"
        onSubmit={methods.handleSubmit(updateSite)}>
        <div className="grid sm:grid-cols-2 gap-[10px]">
          <div className="flex flex-col gap-4">
            <div className="grid grid-cols-[66px_1fr] items-baseline gap-[10px]">
              <div className="text-right">BEST ID</div>
              <div className="bg-[#989CAAAA] text-white text-center rounded-sm pb-[2px] font-bold">
                {data?.site_id}
              </div>
            </div>
            <Field
              title="site_name"
              name="site_name"
              defaultValue={data?.site_name}
              required
              disabled={!access}
            />
            <Field
              title="site_url"
              name="site_url"
              defaultValue={data?.site_url}
              disabled={!access}
            />
            <div className="grid grid-cols-[66px_1fr] items-baseline gap-[10px]">
              <div className="text-right">site_uuid</div>
              <div className="bg-[#989CAAAA] text-white rounded-sm pb-[2px] font-bold px-8 break-all">
                {data?.site_uuid}
              </div>
            </div>
            <Field
              title="site_xml"
              name="site_url_xml"
              defaultValue={data?.site_url_xml}
              required
              disabled={!access}
            />
            <Field title="xml" name="real_xml" defaultValue={data?.real_xml} disabled={!access} />
            <Field
              title="exnode_id"
              name="exnode_id"
              defaultValue={data?.exnode_id}
              disabled={!access}
            />
            <Field
              title="core_url"
              name="core_api_url"
              defaultValue={data?.core_api_url || ""}
              disabled={!access}
            />
            {access && (
              <div
                className="grid grid-cols-[66px_1fr] items-baseline gap-[10px] cursor-pointer"
                onClick={() => setEditCoreToken(true)}>
                <div className="text-right">core_token</div>
                <div className="bg-[#989CAAAA] text-white text-center rounded-sm pt-[3px] max-h-[20px] font-bold">
                  ********
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-4">
            <Tumbler
              title="на бесте"
              name="is_on_best"
              defaultValue={data?.is_on_best}
              disabled={!access}
            />
            <Tumbler
              title="вкл/вкл"
              name="update_turn_status"
              defaultValue={data?.update_turn_status}
              disabled={!access}
            />
            <Tumbler
              title="синхронизатор minmax, шаги"
              name="tumbler_site"
              defaultValue={data?.tumbler_site}
              disabled={!access}
            />
            <Tumbler
              title="проверка на вылеты"
              name="tumbler_scam_checker"
              defaultValue={data?.tumbler_scam_checker}
              disabled={!access}
            />
            <Tumbler
              title="наличие процедур"
              name="has_procedure"
              defaultValue={data?.has_procedure}
              disabled={!access}
            />
            <Tumbler
              title="обновление new"
              name="update_new"
              defaultValue={data?.update_new}
              disabled={!access}
            />
            <Tumbler
              title="Доступ DB"
              name="db_access"
              defaultValue={data?.db_access}
              disabled={!access}
            />
            <Tumbler
              title="xml_json"
              name="xml_json"
              defaultValue={data?.xml_json}
              disabled={!access}
            />
            <Tumbler title="PE" name="is_pe" defaultValue={data?.is_pe} disabled={!access} />
          </div>
        </div>
        {isPE && <PEForm item={data} isPE={isPE} />}
        <div className="flex flex-col gap-4 bg-stale rounded-sm -m-4 p-4">
          <div
            className="font-semibold text-lightFont py-4 px-8 hover:opacity-80 hover:bg-[#FAFAFF55] duration-300 cursor-pointer mb-8"
            onClick={() => setAddExc(!isAddExc)}>
            Добавить исключение
          </div>
          <div
            className={`duration-300 flex flex-col ${
              isAddExc ? "max-h-[200px]" : "max-h-0 overflow-hidden"
            } -mt-[12px]`}>
            <div className="grid grid-cols-[66px_1fr] items-baseline gap-[10px] mt-[12px] mb-8">
              <div className="text-right">Город</div>
              <ReactSelect
                classNamePrefix="SquareSelect"
                options={citiesOptions}
                filterOption={createFilter(selectFilter)}
                onChange={(e: TSelect) => setCurrentCity(e)}
              />
            </div>
            <div className="grid grid-cols-[66px_1fr] items-baseline gap-[10px] mt-[12px] mb-8">
              <div className="text-right">Векторы</div>
              <ReactSelect
                classNamePrefix="SquareSelect"
                options={directionsOptions}
                placeholder="Если не выбрано - по всем векторам"
                filterOption={createFilter(selectFilter)}
                onChange={(e: { label: string; value: TGlobalDirection }) => {
                  if (!currentVectors?.find((el) => el.label === e.label)) {
                    setCurrentVectors([...currentVectors, e]);
                  }
                }}
              />
            </div>
            <div className="flex flex-wrap gap-4 ml-[1px] w-full">
              {currentVectors?.map((el) => (
                <div
                  key={el.label}
                  className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] pl-4 pr-8 font-medium h-[15px]">
                  {el.label}
                  <button
                    type="button"
                    className="relative left-2 rotate-45 text-[12px]"
                    onClick={() => {
                      setCurrentVectors(currentVectors?.filter((el2) => el2.label !== el.label));
                    }}>
                    +
                  </button>
                </div>
              ))}
            </div>
            <button
              className={`${
                disabled ? "opacity-30" : ""
              } self-end rounded-sm font-semibold text-lightFont border border-lightGray bg-stale mt-8 px-[16px] pb-2`}
              type="button"
              onClick={() => {
                !!currentVectors?.length
                  ? baseExcRequest("update", "Создание исключения", {
                      site_id: data.site_id,
                      city_id: currentCity?.value,
                      data: currentVectors?.map((el) => ({
                        currency_from_id: globalCurrencies?.find(
                          (cur) => cur.currency_name === el.value.currency_from
                        )?.currency_id,
                        currency_to_id: globalCurrencies?.find(
                          (cur) => cur.currency_name === el.value.currency_to
                        )?.currency_id,
                      })),
                    })
                  : baseExcRequest("add", "Создание исключения", {
                      site_id: data.site_id,
                      city_id: currentCity?.value,
                    });
              }}
              disabled={disabled}>
              Создать исключение
            </button>
          </div>
          {data?.exceptions?.map((el) => (
            <Exception
              key={`${data?.site_url_xml}-${el.city_id}`}
              siteId={data.site_id}
              item={el}
              baseRequest={baseExcRequest}
              citiesOptions={citiesOptions}
              currenciesOptions={currenciesOptions}
            />
          ))}
        </div>
        {data?.db_data?.map((el) => (
          <DBForm
            key={`${data?.site_url_xml}-${el.driver}`}
            item={el}
            site_id={data?.site_id}
            setIsLoading={setIsLoading}
            setMessage={setMessage}
            setStatus={setStatus}
            fetchAdminSites={fetchAdminSites}
            access={access}
          />
        ))}
        {access && (
          <button className="relative top-[-8px] prime-button-sm self-end justify-self-end bg-font rounded-sm text-xs mb-0">
            Обновить
          </button>
        )}
        {isEditCoreToken && (
          <Popup closeModal={() => setEditCoreToken(false)} noPadding>
            <div className="w-full table-header flex justify-center">ИЗМЕНЕНИЕ ТОКЕНА</div>
            <div className="p-[20px] text-xs flex flex-col gap-4">
              <Field title="core_token" name={`core_api_token`} defaultValue={""} required />
              <button
                type="button"
                className="prime-button-sm bg-font mt-[16px]"
                onClick={updateCoreToken}>
                Обновить токен
              </button>
            </div>
          </Popup>
        )}
      </form>
    </FormProvider>
  );
};
